<template>
  <p></p>
  <div v-if="cntSelBill!=1" class="container-fluid">{{ $t('wizards.createpartners.chooseOneAccount') }}</div>
  <div v-if="cntSelBill==1" class="container-fluid">
      <div v-if="isaadj=='close'">
        <div v-if="resusers.length>0" class="alert alert-success">
          <p>{{ $t('wizards.createpartners.usersCreated') }}</p>
          <p v-for="user in resusers" :key="user"><span>{{ $t('wizards.createpartners.login') }}</span> {{user.login}} <span>{{ $t('wizards.createpartners.password') }}</span> {{user.pass}} <span>{{ $t('wizards.createpartners.role') }}</span> {{user.role}}</p>
        </div>

        <div class="form-group row">
          <div class="col-2">
            <div>{{ $t('wizards.createpartners.demobill') }}
              <el-checkbox v-model="data.demobills" type="checkbox" :disabled="data_type=='demo'" :readonly="data_type=='demo'"></el-checkbox>
            </div>
            <div>{{ $t('wizards.createpartners.createPartner') }}
              <input v-model="createPartner" type="checkbox">
            </div>
            <div v-if="createPartner">{{ $t('wizards.createpartners.createAnAdmin') }}
              <input v-model="createAdmin" type="checkbox">
            </div>
          </div>
          <div class="col">
            <div v-if="createPartner" class="form-group row text-right">
              <label class="col-3 col-form-label">{{ $t('wizards.createpartners.partnerName') }}</label>
              <div class="col-9">
                <input v-model="data.name" v-maska="mask_mnemo_partner_name" class="form-control" type="text">
              </div>
            </div>
          </div>
          <div class="col">
            <div v-if="createAdmin" class="form-group row text-right">
              <label class="col-3 col-form-label">{{ $t('wizards.createpartners.partnerAdmin') }}</label>
              <div class="col-9">
                <input v-model="data.admin_login" v-maska="mask_mnemolg" class="form-control" type="text">
              </div>
            </div>
          </div>
          <div class="col">
            <div v-if="createAdmin" class="form-group row text-right">
              <label class="col-3 col-form-label">{{ $t('wizards.createpartners.password') }}</label>
              <div class="col-9">
                <input v-model="data.admin_pass" class="form-control" type="text">
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div v-if="createPartner" class="alert alert-secondary">
              <p class="btn p-0 text-primary" @click="settingShowPartner=!settingShowPartner">{{ $t('wizards.createpartners.settingshow') }}</p>
              <div v-if="settingShowPartner" class="alert alert-info">
                <div v-for="setting in settings" :key="setting.code">
                  <label><input name="setting" type="checkbox" :value="setting.code" v-model="selSettingPartner"> {{ $t('billmanager.billmanagersetting.'+setting.code) }}</label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="alert alert-secondary">
          <p class="btn p-0 text-primary" @click="additionalShow=!additionalShow">{{ $t('wizards.createpartners.extraOptions') }}</p>
          <div v-if="additionalShow" class="alert alert-info">
            <div class="container-fluid">
              <div class="form-group row">
                <label class="col-md-2 col-form-label">{{ $t('wizards.createpartners.timezone') }}</label>
                <div class="col-md-10">
                  <Select2 v-model="additionalData.timezone" :options="tz_list" :settings="{ width: '100%' }" />
                </div>
              </div>
              <hr>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">{{ $t('wizards.createpartners.templateselect') }}</label>
                <div class="col-md-10">
                  <Select2 v-model="additionalData.template" :options="template_list" :settings="{ width: '100%' }" />
                </div>
              </div>
              <hr>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">{{ $t('bill.bill.logotype') }}</label>
                <div class="col-md-10">
                  <Select2 v-model="additionalData.logotype" :options="logotype_list" :settings="{ width: '100%', allowClear: true }" />
                </div>
              </div>
              <hr>
              <div class="form-group row">
                <label class="col-md-2 col-form-label">{{ $t('bill.bill.coef_type') }}</label>
                <div class="col-md-10">
                  <Select2 v-model="additionalData.coef_type" :options="coef_type_list" :settings="{ width: '100%' }" />
                </div>
              </div>
              <hr>
              <div v-if="selOneBill==1" class="form-group row">
                <label class="col-md-2 col-form-label">{{ $t('wizards.createpartners.currency') }}</label>
                <div class="col-md-10">
                  <Select2
                      v-model="additionalData.currency"
                      :options="currenciesOptions"
                      :settings="{ width: '100%' }"
                  />
                </div>
              </div>
              <hr v-if="selOneBill==1">
              <div class="form-group row">
                <div class="col">
                  <div class="row">
                    <div class="col-3">
                      <label>{{ $t('wizards.createpartners.minimumBet') }}</label>
                    </div>
                    <div class="col-3">
                      <input class="form-control" type="number" v-model="additionalData.bet_min">
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="row">
                    <div class="col-3">
                      <label>{{ $t('wizards.createpartners.maximumBet') }}</label>
                    </div>
                    <div class="col-3">
                      <input class="form-control" type="number" v-model="additionalData.bet_max">
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <hr>
              <div class="form-group row">
                <div class="col">
                  <label>{{ $t('wizards.createpartners.button1') }}</label>
                  <input class="form-control" type="number" v-model="additionalData.but1">
                </div>
                <div class="col">
                  <label>{{ $t('wizards.createpartners.button2') }}</label>
                  <input class="form-control" type="number" v-model="additionalData.but2">
                </div>
                <div class="col">
                  <label>{{ $t('wizards.createpartners.button3') }}</label>
                  <input class="form-control" type="number" v-model="additionalData.but3">
                </div>
                <div class="col">
                  <label>{{ $t('wizards.createpartners.button4') }}</label>
                  <input class="form-control" type="number" v-model="additionalData.but4">
                </div>
                <div class="col">
                  <label>{{ $t('wizards.createpartners.button5') }}</label>
                  <input class="form-control" type="number" v-model="additionalData.but5">
                </div>
                <div class="col">
                  <label>{{ $t('wizards.createpartners.button6') }}</label>
                  <input class="form-control" type="number" v-model="additionalData.but6">
                </div>
              </div>
            </div>
          </div>
        </div>

        <hr>

        <div class="alert alert-secondary">
          <div class="form-group row">
            <div class="col-1">{{ $t('wizards.createpartners.quantity') }}<input v-model="data.cnt" class="form-control" type="number">
            </div>
            <div class="col">{{ $t('wizards.createpartners.prefixesForAccountNames') }}<input v-model="data.pref_ag" v-maska="mask_bill_name_mnemo" class="form-control" type="text">
            </div>
            <div class="col">{{ $t('wizards.createpartners.prefixForCashierLogin') }}<input v-model="data.pref_cashier_login" v-maska="mask_mnemolg" class="form-control" type="text">
            </div>
          </div>
          <div class="row">
            <div class="col">{{ $t('wizards.createpartners.passaslogin') }} <input v-model="passaslogin" type="checkbox"></div>
            <div class="col">{{ $t('wizards.createpartners.parameterSubstitutionPossible') }} {{mnemcods}}</div>
          </div>
        </div>

        <div class="alert alert-secondary">
          <p class="btn p-0 text-primary" @click="settingShow=!settingShow">{{ $t('wizards.createpartners.settingshow') }}</p>
          <div v-if="settingShow" class="alert alert-info">
            <div v-for="setting in settings" :key="setting.code">
              <label><input name="setting" type="checkbox" :disabled="setting.code=='bill_disable_multi_login' && passaslogin" :value="setting.code" v-model="selSetting"> {{ $t('billmanager.billmanagersetting.'+setting.code) }}</label>
            </div>

            <div>
              <label><input name="show_nearest_event" type="checkbox" v-model="show_nearest_event"> {{ $t('billmanager.billmanagersetting.show_nearest_event') }}</label>
            </div>

            <div>
              <label><input name="preview_auto_payout" type="checkbox" v-model="preview_auto_payout"> {{ $t('billmanager.billmanagersetting.preview_auto_payout') }}</label>
            </div>
          </div>
        </div>

        <div class="alert alert-secondary">
          <p class="btn p-0 text-primary" @click="linkGames=!linkGames">{{ $t('wizards.createpartners.connectingGames') }}</p>
          <div v-if="linkGames" class="alert alert-info">
            <label class="col-md-2 col-form-label">{{ $t('wizards.createpartners.parentGames') }}<el-checkbox v-model="content.parents_games_active" @change="change" size="large" ></el-checkbox></label>
            <div class="row">
              <div class="col-md-2">
                <Select2 :options="game_names_list" :settings="{ width: '100%' }" v-model="selectedGame" />
              </div>
              <button class="btn-success" @click="linkGame">{{ $t('wizards.createpartners.linkGame') }}</button>
            </div>
            <template v-if="linkGames || content.parents_games_active">
              <vue-good-table
                  :columns="columns_l"
                  :rows="onlyConnected"
                  :sort-options="{ enabled: false }"
                  compactMode>
                <template #table-row="props">
                  <div v-if="props.column.field == 'number'">{{ props.index + 1 }}</div>
                  <div v-if="props.column.field == 'game_name'">
                    {{ props.row.game_name}}
                    <span v-if="props.row.id">({{ props.row.id }})</span>
                  </div>
                  <input v-if="props.column.field == 'display_in_cashier'"
                         name="sel_games_display_in_cashier"
                         type="checkbox"
                         :value="props.row.id"
                         v-model="sel_games_display_in_cashier">
                  <GameBillActions v-if="props.column.field == 'jackpot'" :selRow="props.row"></GameBillActions>
                  <button @click="unlinkGame(props.row.id)" v-if="props.column.field == 'actions'" type="button" class="btn btn-danger p-1" :title="$t('bill.billactions.deleteAccount')">
                    <div class="icon-size"><font-awesome-icon icon="times" /></div>
                  </button>
                </template>
              </vue-good-table>
            </template>
          </div>
        </div>
        <vue-good-table v-if="rows.length>0"
                        :columns="columns"
                        :rows="rows"
                        :sort-options="{enabled: false}"
                        :lineNumbers="true"
                        compactMode>
          <template #emptystate>
            <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
          </template>
          <template #table-row="props">
            <input v-if="props.column.field!='user_name' && props.column.field!='name'" v-model="rowsdata[props.index][props.column.field]" v-maska="mask_mnemo" class="form-control" type="text">
            <input v-if="props.column.field=='user_name' || props.column.field=='name'" v-model="rowsdata[props.index][props.column.field]" v-maska="mask_mnemo_partner_name" class="form-control" type="text">
          </template>
        </vue-good-table>
        <p></p>
        <button v-if="rowsdata.length>0 || createPartner" @click="create" type="button" class="btn btn-success" :title="$t('wizards.createpartners.create')">{{ $t('wizards.createpartners.create') }}</button>

      </div>

    <div v-if="isaadj!='close'">
      <JackpotsEdit></JackpotsEdit>
    </div>
  </div>
</template>

<script setup>
import WizardsService from "../../services/wizards.service";
import BillService from "../../services/bill.service";
import ReceiptService from "../../services/receipt.service";
import CurrencieService from "../../services/currencie.service";
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {VueGoodTable} from "vue-good-table-next";
import {computed, defineEmits, inject, onMounted, provide, ref, watch, watchEffect} from "vue";
import {useStore} from "vuex";
import LogotypesService from "../../services/logotypes.service";
import GameBillActions from "../GameBill/GameBillActions.vue";
import JackpotsEdit from "../Jackpots/JackpotsEdit.vue";

import GameService from "@/services/game.service";
import JackpotService from "@/services/jackpot.service";

const $t = inject('$t')

const set_result = inject('set_result')
const cntSelBill = inject('cntSelBill')
const selOneBill = inject('SelOneBill')

const store=useStore()

const emit = defineEmits(['selRow', 'onGetRows'])

const settings = ref([])
const selSetting = ref([])
const selSettingPartner = ref([])

const show_nearest_event = ref(true)
const preview_auto_payout = ref(false)
const data_type = ref(null)

const currenciesOptions = ref([])

const getBillsInfoAll = inject('getBillsInfoAll')

const mask_mnemo_partner_name={ mask: 'Z*', tokens: { 'Z': { pattern: /[^.,'"`]|[-]/ }}}

const mask_mnemo = inject('mask_mnemo')
const mask_mnemomin = inject('mask_mnemomin')
const mask_mnemolg = inject('mask_mnemolg')
const mask_mnemominlg = inject('mask_mnemominlg')
const mask_bill_name_mnemo = inject('mask_bill_name_mnemo')

const passaslogin = ref(false)

const hexTokens = ({
  F: {
    pattern: /[0-9a-fA-F]/,
    transform: v => v.toLocaleUpperCase()
  }
})

const genPass = inject('genPass')
const tz_list = inject('tz_list')
const template_list = ref([])

const demobill = ref(false)
const createPartner = ref(false)
const createAdmin = ref(false)

const data = ref({name: '', admin_login: '', admin_pass: genPass(), 'cnt': 0, pref_ag: '', pref_cashier_login: ''})

const rowsdata = ref([])
const resusers = ref([])
const resusersofDB = ref([])
const additionalShow = ref(false)
const additionalData = ref({'coef_type': 'decimal'})
const currentBillInfo = computed(() => store.state.bill.selOneBillInfo)

const settingShow = ref(false)
const settingShowPartner = ref(false)
const linkGames = ref(false)

const mnemcods = '{{parent_id}} {{iter}} {{bill_id}}'

const logotype_list = ref([])

const selectedGame = ref(null)
const gamesData = ref([])
const connectedGames = ref([])
const sel_games_display_in_cashier = ref([])

const content = ref({parents_games_active: true})
const linkTable = ref(true)

const onlyConnected = ref([])
const game_names_list = ref([])

const coef_type_list = [
  {id: 'decimal', text: 'Decimal Odds'},
  {id: 'american', text: 'American Odds'},
]

function fieldsCmp()
{
  let res = []

  res.push({type: 'el-input', code: 'allowed_rejects', label: $t('bill.bill.allowed_rejects')})
  //res.push({type: 'el-select', code: 'coef_type', label: $t('bill.bill.coef_type'), list: coef_type_list, clear: true})

  return res
}
const fields = computed(fieldsCmp)

const jackpots = ref([]);
provide('jackpots', jackpots)

const jackpotData = ref({})

const perPageDropdown = inject('perPageDropdown')

const columns = computed(() => [
  {
    label: $t('wizards.createpartners.check'),
    field: 'name',
  },
  {
    label: $t('wizards.createpartners.cashierLogin'),
    field: 'login',
  },
  {
    label: $t('wizards.createpartners.cashiersName'),
    field: 'user_name',
  },
  {
    label: $t('wizards.createpartners.cashierPassword'),
    field: 'pass',
  }
])

const columns_l = computed(() => [
  {
    label: $t('gamebill.gamesbill.number'),
    field: 'number',
    width: '100px',
  },
  {
    label: $t('gamebill.gamesbill.gameName'),
    field: 'game_name',
    width: '250px',
  },
  {
    label: $t('gamebill.gamesbill.display_in_cashier'),
    field: 'display_in_cashier',
    width: '250px',
  },
  {
    label: $t('gamebill.gamesbill.jackpots'),
    field: 'jackpot',
    width: '270px',
  },
  {
    label: $t('app.public.action'),
    field: 'actions',
    width: '100px',
  }
])

const rows = computed(generatelist)

function change()
{
  linkTable.value = false

  game_names_list.value=[]
  const allGames = gamesData.value
  allGames.forEach(game => game_names_list.value.push({id: game.id, text: game.game_name + ' (' + game.id + ')'}))

  onlyConnected.value = []
  connectedGames.value = []
  sel_games_display_in_cashier.value = []

  if (content.value.parents_games_active){
    getSelectedGames()
  }
}

function getDataBill()
{
  additionalData.value.timezone=currentBillInfo.value.timezone

  additionalData.value.bet_min=currentBillInfo.value.bet_min
  additionalData.value.bet_max=currentBillInfo.value.bet_max

  additionalData.value.but1=currentBillInfo.value.but1
  additionalData.value.but2=currentBillInfo.value.but2
  additionalData.value.but3=currentBillInfo.value.but3
  additionalData.value.but4=currentBillInfo.value.but4
  additionalData.value.but5=currentBillInfo.value.but5
  additionalData.value.but6=currentBillInfo.value.but6
  additionalData.value.hardlimit=currentBillInfo.value.hardlimit
}
watch(currentBillInfo, getDataBill)

function getBillsTree()
{
  store.dispatch('bill/updBills')
}

function create()
{
  data.value.id = selOneBill.value;
  data.value.data = rowsdata.value;
  data.value.additional = additionalData.value;
  data.value.setting_partner = selSettingPartner.value;
  data.value.setting = selSetting.value;
  data.value.show_nearest_event = show_nearest_event.value;
  data.value.preview_auto_payout = preview_auto_payout.value;
  data.value.game = connectedGames.value;
  data.value.display_cash = sel_games_display_in_cashier.value;
  data.value.display_admin = unvisibleGames.value
  data.value.jp = jackpots.value

  let err_list=''

  if(additionalData.value.template==undefined) {
    err_list+=$t('wizards.createpartners.template_not_sel')+' '
  }

  if(additionalData.value.coef_type==undefined) {
    err_list+=$t('wizards.createpartners.coef_type_not_sel')+' '
  }

  if(selOneBill.value==1 && additionalData.value.currency==undefined) {
    err_list+=$t('wizards.createpartners.currency_not_sel')+' '
  }

  if(err_list!='') {
    set_result('406', null, err_list)
    return
  }

  if(createPartner.value!==true) {
    data.value.name=''
  }

  if(createAdmin.value!==true) {
    data.value.admin_login=''
    data.value.admin_pass=''
  }

  WizardsService.createPartners(data.value).then(
      (response) => {
        resusersofDB.value=response.data
        getBillsTree()
        getBillsInfoAll()
        getLogins()
        createPartner.value = false
        createAdmin.value = false
        data.value = {name: '', admin_login: '', admin_pass: genPass(), 'cnt': 0, pref_ag: '', pref_cashier_login: ''}
        additionalShow.value = false
        settingShow.value = false
        settingShowPartner.value = false
        getSettings()
      },
      (error) => {
        resusers.value=''
        let nam_err=''
        let err_data=error.response.data.err_info
        if(err_data.indexOf('###')!=-1) {
          let result = err_data.match(/[#][#][#](.*)[#][#][#]/);
          nam_err=$t('wizards.createpartners.loginexist')+' '+result[1]+' '+$t('wizards.createpartners.loginareexist')
        }

        set_result(error.response.status, error, nam_err)
      }
  )
}

function generatelist()
{
  let rw = []

  let agent_name='';
  let cashier_name='';
  let cashier_login='';
  let pass='';
  if(data.value.cnt>0) {
    for(let i=1;i<=data.value.cnt;i++){
      if(data.value.pref_ag!=='') {
        agent_name=data.value.pref_ag
        //agent_name=agent_name.replace('{{iter}}', i)
        //agent_name=agent_name.replace('{{parent_id}}', selOneBill.value)
      } else {
        agent_name='{{bill_id}}'
      }

      if(data.value.pref_cashier_login!=='') {
        cashier_login=data.value.pref_cashier_login
      } else {
        cashier_login='{{bill_id}}'
      }
      cashier_name = cashier_login
      if(passaslogin.value) {
        pass=cashier_login
      } else {
        pass=genPass()
      }

      rw.push({'name': agent_name, 'login': cashier_login, 'user_name': cashier_name, 'pass': pass})
    }
  }

  rowsdata.value=rw

  return rw
}

function getLogins()
{
  let loginsList=[]

  for (let cur in resusersofDB.value) {
    if(resusersofDB.value[cur].ret_user_role=='Administrator') {
      loginsList.push({"login": resusersofDB.value[cur].ret_user_login, "pass": resusersofDB.value[cur].ret_user_pass, "role": $t('wizards.createpartners.admin')})
    }

    if(resusersofDB.value[cur].ret_user_role=='Cashier') {
      loginsList.push({"login": resusersofDB.value[cur].ret_user_login, "pass": resusersofDB.value[cur].ret_user_pass, "role": $t('wizards.createpartners.cashier')})
    }
  }

  resusers.value=loginsList
}

function getSettings()
{
  if(selOneBill.value!=undefined && selOneBill.value!='') {
    getDataBill()

    BillService.getSettings(selOneBill.value).then(
        (response) => {
          settings.value = response.data.settings;

          preview_auto_payout.value = response.data.preview_auto_payout

          data_type.value = response.data.data_type

          data.value.demobills = false
          if(response.data.data_type=='demo') {
            data.value.demobills = true
          }

          selSetting.value=[ "bill_sub_block", "user_create", "user_block", "user_change_pass", "cashier_bet_but_val" ]
          selSettingPartner.value=[ "bill_sub_block", "user_create", "user_block", "user_change_pass", "cashier_bet_but_val"]

          for (let cur in settings.value) {
            if (settings.value[cur].code == 'bill_enable_manual_bet_sum' && settings.value[cur].is_set == 1) {
              selSetting.value=[ "bill_sub_block", "user_create", "user_block", "user_change_pass", "cashier_bet_but_val", "bill_enable_manual_bet_sum" ]
              selSettingPartner.value=[ "bill_sub_block", "user_create", "user_block", "user_change_pass", "cashier_bet_but_val", "bill_enable_manual_bet_sum" ]

            }
          }
        },
        (error) => {
          set_result(error.response.status, error)
        }
    )
  }
}
watchEffect(getSettings)

function getTemplates()
{
  if(selOneBill.value!=undefined && selOneBill.value!='') {

    ReceiptService.getTemplatesList(selOneBill.value).then(
        (response) => {
          template_list.value = JSON.parse(response.data)

          for(let tls in template_list.value) {
            if(template_list.value[tls].is_set=='1') {
              additionalData.value.template=template_list.value[tls].id
            }
          }
        },
        (error) => {
          set_result(error.response.status, error)
        }
    )
  }
}
watchEffect(getTemplates)

function passasloginFn(newv, oldv)
{
  if(newv && selSetting.value.indexOf('bill_disable_multi_login')==-1) {
    selSetting.value.push('bill_disable_multi_login')
  }
}
watch(passaslogin, passasloginFn)

function getSettByCurency(selcur)
{
  BillService.getSettByCurency(selcur).then(
      (response) => {
        additionalData.value.bet_min=response.data.bet_min
        additionalData.value.bet_max=response.data.bet_max

        additionalData.value.but1=response.data.but1
        additionalData.value.but2=response.data.but2
        additionalData.value.but3=response.data.but3
        additionalData.value.but4=response.data.but4
        additionalData.value.but5=response.data.but5
        additionalData.value.but6=response.data.but6
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function adcurrencyFn(newval)
{
  if(selOneBill.value==1 && newval.currency!==undefined)
  {
    getSettByCurency(newval.currency)
  }
}
watch(additionalData.value, adcurrencyFn)

function getCurency()
{
  CurrencieService.getCurrenciesList().then(
      (response) => {
        currenciesOptions.value = response.data;
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function getLogotypes()
{
  LogotypesService.getLogotypesList().then(
      (response) => {
        logotype_list.value = response.data
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

const unvisibleGames = ref([])
function getSelectedGames()
{
  if (selOneBill.value != undefined) {
    GameService.getGamesByBill(selOneBill.value).then(
        (response) => {
          gamesData.value = response.data
          game_names_list.value = []
          onlyConnected.value = []
          connectedGames.value = []
          sel_games_display_in_cashier.value = []
          unvisibleGames.value = []

          for (let key in gamesData.value) {
            if (gamesData.value[key].isselect != 1 && gamesData.value[key].display_in_admin != 'false') {
              game_names_list.value.push({ "id": gamesData.value[key].id, "text": gamesData.value[key].game_name + ' (' + gamesData.value[key].id + ')' })
            }
            if (gamesData.value[key].isselect == 1 && gamesData.value[key].display_in_admin != 'false') {
              onlyConnected.value.push(gamesData.value[key])
              connectedGames.value.push(gamesData.value[key].id)
            }
            if (gamesData.value[key].display_in_cashier == 'true' ) {
              sel_games_display_in_cashier.value.push(gamesData.value[key].id)
            }
            if (gamesData.value[key].display_in_admin == 'false'){
              unvisibleGames.value.push({ id: gamesData.value[key].id, display_in_admin: false })
            }

            gamesData.value[key].jackpots = JSON.parse(gamesData.value[key].jackpots)
            gamesData.value[key].nextGrade = getNextGrade(gamesData.value[key].jackpots)

            if (gamesData.value[key].jackpots) {
              gamesData.value[key].jackpots.forEach((jackpot) => {
                if (jackpot.jackpot_id) {
                  getDataJackpot(jackpot.jackpot_id)
                }
                const existingGameJackpots = jackpots.value.find(game => game.id == gamesData.value[key].id)
                if (existingGameJackpots) {
                  existingGameJackpots.jackpots = gamesData.value[key].jackpots
                } else {
                  jackpots.value.push({
                    id: gamesData.value[key].id,
                    jackpots: gamesData.value[key].jackpots
                  })
                }
              })
            }
          }
        },
        (error) => {
          set_result(error.response.status, error);
        }
    )
  }
}
provide('getSelectedGames', getSelectedGames)

function linkGame()
{
  if (selectedGame.value) {
    if (!connectedGames.value.includes(selectedGame.value)) {
      connectedGames.value.push(selectedGame.value);
    }
    if (!sel_games_display_in_cashier.value.includes(selectedGame.value)) {
      sel_games_display_in_cashier.value.push(selectedGame.value);
    }

    const gameData = gamesData.value.find(game => game.id == selectedGame.value);
    if (gameData) {
      onlyConnected.value.push(gameData);
      const dataIndex = gamesData.value.indexOf(gameData);
      if (dataIndex !== -1) {
        gamesData.value.splice(dataIndex, 1);
      }
      const listIndex = game_names_list.value.findIndex(game => game.id == gameData.id);
      if (listIndex !== -1) {
        game_names_list.value.splice(listIndex, 1);
      }
    //  gameData.jackpots=JSON.parse(gameData.jackpots)
    //  gameData.nextGrade=getNextGrade(gameData.jackpots)
    }
  }
}

function unlinkGame(gameId)
{
  const index = connectedGames.value.findIndex(game => game == gameId);
  if (index !== -1) {
    connectedGames.value.splice(index, 1);
  }

  const gameData = onlyConnected.value.find(game => game.id == gameId);
  if (gameData) {
    onlyConnected.value.splice(onlyConnected.value.indexOf(gameData), 1);
    gamesData.value.push(gameData);
    game_names_list.value.push({ id: gameData.id, text: gameData.game_name + ' (' + gameData.id + ')' });
    game_names_list.value.sort((a, b) => a.id - b.id);
  }
}

const game_id = ref('')
provide('game_id', game_id)

const grade_next = ref('')
provide('grade_next', grade_next)

const grade_edit = ref('')
provide('grade_edit', grade_edit)

const isaadj = ref('close')
provide('isaadj', isaadj)

const fromCP = ref(true)
provide('fromCP', fromCP)

const jackpot_id = ref('')
provide('jackpot_id', jackpot_id)

function addJackpots(aj, gid='', jid='', grade='')
{
  isaadj.value=aj
  if(isaadj.value!='close') {
    game_id.value=gid.id
    grade_next.value=gid.nextGrade

    grade_edit.value=''
    if(grade != undefined) {
      grade_edit.value=grade
    }

    jackpot_id.value=jid
  } else {
    game_id.value=''
    grade_next.value=''
    jackpot_id.value=''
  }
}
provide('addJackpots', addJackpots)

function getNextGrade(jackpots)
{
  let grade_list = ['BIG', 'SUPER', 'MEGA']

  let nextGrade='';

  for (let jackpot in jackpots) {
    let cur=jackpots[jackpot]
    if(cur.grade_code==grade_list[0]) {
      grade_list.shift()
    }
  }

  if(grade_list[0]!=undefined) {
    nextGrade=grade_list[0]
  }

  return nextGrade;
}

function getDataJackpot(jackpot_id) {
    JackpotService.get(jackpot_id, selOneBill.value).then(
        (response) => {
          jackpotData.value = response.data;

          for (let r in gamesData.value) {
              if (gamesData.value[r].jackpots) {
                for (let q in gamesData.value[r].jackpots) {
                  // eslint-disable-next-line no-prototype-builtins
                  if (gamesData.value[r].jackpots.hasOwnProperty(q) && gamesData.value[r].jackpots[q].jackpot_id == jackpotData.value.id) {
                    gamesData.value[r].jackpots[q].data = jackpotData.value
                  }
                }
              }
            }
        },
        (error) => {
          set_result(error.response.status, error);
        }
    )
}

function saveJP(params = '', ng = '', dataJP) {
  game_id.value = params
  grade_next.value = ng

  if(grade_edit.value != '') {
    grade_next.value = grade_edit.value
  }
  grade_edit.value = ''

  const gameID = onlyConnected.value.find(game => game.id == game_id.value)

  if (jackpot_id.value && jackpot_id.value>0) {
    const oldJackpot = gameID.jackpots.find(jackpot => jackpot.jackpot_id == jackpot_id.value)
    if (oldJackpot) {
      oldJackpot.id_game = game_id.value
      oldJackpot.grade_code = dataJP.jackpots_grade
      oldJackpot.jackpot_status = dataJP.jackpot_status
      oldJackpot.data = dataJP
    }
  } else {
    if (gameID !== null && typeof gameID !== 'undefined') {
      if (!gameID.jackpots) {
        gameID.jackpots = []
      }

      const newJackpot = {
        id_game: game_id.value,
        id_jackpot: '',
        jackpot_id: -1,
        grade_code: grade_next.value,
        jackpot_status: dataJP.jackpot_status,
        type: 'local',
        data: dataJP
      }

      const existingJackpot = gameID.jackpots.find(jackpot => jackpot.id_game == newJackpot.id_game && jackpot.grade_code == newJackpot.grade_code)
      if (!existingJackpot) {
        gameID.jackpots.push(newJackpot)
        gameID.nextGrade = getNextGrade(gameID.jackpots)
      }
    }
  }

  const existingGameJackpots = jackpots.value.find(game => game.id == gameID.id)
  if (existingGameJackpots) {
    existingGameJackpots.jackpots = gameID.jackpots
  } else {
    jackpots.value.push({
      id: gameID.id,
      jackpots: gameID.jackpots
    })
  }
}
provide('saveJP', saveJP)

onMounted(() => {
  getDataBill()
  getCurency()
  getLogotypes()
  getSelectedGames()
})
</script>

<style scoped>

</style>
