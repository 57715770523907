<template>
  <button :class="jackpot.grade_code+jackpot.type" v-for="jackpot in props.selRow.jackpots" :key="jackpot" @click="addJackpots('edit', props.selRow, jackpot.jackpot_id, jackpot.grade_code)" type="button" class="btn p-1">
    <font-awesome-icon v-if="jackpot.jackpot_status=='active'" icon="pencil-alt" />
    <font-awesome-icon v-if="jackpot.jackpot_status=='not_active'" icon="lock" />
    {{jackpot.grade_code}}
  </button>
  <button v-if="props.selRow.nextGrade!=undefined && props.selRow.nextGrade!='' && (is_super==true || is_admin_sb==true)" @click="addJackpots('new', props.selRow)" type="button" class="btn btn-success p-1">
    <div class="icon-size"><font-awesome-icon icon="plus" /></div>
  </button>
</template>

<script setup>
import {computed, defineProps, inject} from "vue";

const props = defineProps({
  selRow: Object
});

const addJackpots = inject('addJackpots')

const is_super = inject('is_super')
const is_admin_sb = inject('is_admin_sb')
</script>

<style scoped>
  button {
    margin: 3px;
  }

  button.BIGlocal {
    background-color: #cd7f32;
  }

  button.SUPERlocal {
    background-color: silver;
  }

  button.MEGAlocal {
    background-color: gold;
  }

  button.BIGglobal {
    background-color: dodgerblue;
  }

  button.SUPERglobal {
    background-color: #468847;
  }

  button.MEGAglobal {
    background-color: aqua;
  }
</style>
